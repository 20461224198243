
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAEH6FoD32kZqZSgYNHnmICavgBx4Qtdhs",
  authDomain: "dwilliams-74b9e.firebaseapp.com",
  projectId: "dwilliams-74b9e",
  storageBucket: "dwilliams-74b9e.appspot.com",
  messagingSenderId: "878837419108",
  appId: "1:878837419108:web:30fdac1f69c54819cc2371",
  measurementId: "G-512YMZ3W1H"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);