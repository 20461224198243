import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

function useCategories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  const addCategory = async (categoryName) => {
    try {
      const docRef = await addDoc(collection(db, "categories"), {
        name: categoryName,
      });
      const newCategory = {
        id: docRef.id,
        name: categoryName,
      };
      setCategories([...categories, newCategory]);
    } catch (error) {
      console.error("Error adding category: ", error);
    }
  };
  const updateCategory = async (categoryId, newCategoryName) => {
    try {
      const categoryDocRef = doc(db, "categories", categoryId);
      await setDoc(categoryDocRef, {
        name: newCategoryName,
      });
      const updatedCategories = categories.map((category) =>
        category.id === categoryId
          ? { ...category, name: newCategoryName }
          : category
      );
      setCategories(updatedCategories);
    } catch (error) {
      console.error("Error updating category: ", error);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      const categoryDocRef = doc(db, "categories", categoryId);
      await deleteDoc(categoryDocRef);
      const updatedCategories = categories.filter(
        (category) => category.id !== categoryId
      );
      setCategories(updatedCategories);
    } catch (error) {
      console.error("Error deleting category: ", error);
    }
  };

  return { categories, addCategory, updateCategory, deleteCategory };
}

export default useCategories;
