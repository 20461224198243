import React, { Fragment, useEffect, useState } from "react";
import { MagicMotion } from "react-magic-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { Menu, Transition } from "@headlessui/react";
import SearchIcon from "../utils/icons/Search.svg";
import CancelIcon from "../utils/icons/Cancel.svg";
import FiltersIcon from "../utils/icons/Filters.svg";
import WallpaperImage from "../utils/images/wallpaperProperties.jpg";
import PropertyCard from "../commons/PropertyCard";
import CustomSpinner from "../commons/customSpinner";
import useProperties from "../hooks/useProperties";
import useCategories from "../hooks/useCategories";
import Select from "../commons/Select";

function Properties() {
  const [showFilters, setShowFilters] = useState(false);
  const [render, setRender] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRooms, setSelectedRooms] = useState("");
  const [selectedEnvironments, setSelectedEnvironments] = useState("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearchOrFilterActive, setIsSearchOrFilterActive] = useState(false);
  const {
    properties,
    isLoading,
    nextProperties,
    hasMore,
    allPropertiesUnlimited,
  } = useProperties({ queryParameter: "" });
  const { categories } = useCategories();

  useEffect(() => {
    setRender(properties);
  }, [properties]);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setIsSearching(searchText === "" ? false : true);
    setSearch(searchText);
    const searchProperties = allPropertiesUnlimited.filter((property) => {
      const normalizedTitle = normalizeText(property.title);
      const normalizedCity = normalizeText(property.city);
      const normalizedCountry = normalizeText(property.country);
      const normalizedState = normalizeText(property.state);
      const searchMatch =
        normalizedTitle.includes(searchText) ||
        normalizedCity.includes(searchText) ||
        normalizedCountry.includes(searchText) ||
        normalizedState.includes(searchText);
      const environmentMatch =
        selectedEnvironments === "" ||
        property.environments === Number(selectedEnvironments);
      return searchMatch && environmentMatch;
    });
    setRender(searchProperties);
    setIsSearchOrFilterActive(searchText !== "" || isFiltering);
  };

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const handleFilter = async () => {
    setIsFiltering(true);
    const updatedFilteredProperties = allPropertiesUnlimited.filter(
      (property) => {
        const categoryMatch =
          selectedCategory === "" || property.category === selectedCategory;
        const roomsMatch =
          selectedRooms === "" ||
          (selectedRooms === "+6"
            ? property.rooms > 6
            : property.rooms === Number(selectedRooms));
        const environmentsMatch =
          selectedEnvironments === "" ||
          (selectedEnvironments === "+6"
            ? property.environments > 6
            : property.environments === Number(selectedEnvironments));
        return categoryMatch && roomsMatch && environmentsMatch;
      }
    );

    setRender(updatedFilteredProperties);
    setIsSearchOrFilterActive(true);
  };

  const isFilterButtonDisabled = () => {
    return (
      !selectedCategory && !selectedRooms && !selectedEnvironments && !search
    );
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleEnvironmentsChange = (selectedValue) => {
    setSelectedEnvironments((prevSelected) =>
      prevSelected === selectedValue ? "" : selectedValue
    );
  };

  const handleRoomsChange = (selectedValue) => {
    setSelectedRooms((prevSelected) =>
      prevSelected === selectedValue ? "" : selectedValue
    );
  };

  const clearSearch = () => {
    setSearch("");
    setRender(properties);
    setIsSearching(false);
    setIsSearchOrFilterActive(false);
  };

  const clearFilters = () => {
    setSelectedCategory("");
    setSelectedEnvironments("");
    setSelectedRooms("");
    setSearch("");
    setIsFiltering(false);
    setRender(properties);
    setIsSearchOrFilterActive(false);
  };

  return (
    <section>
      <div>
        <div className="relative h-[45vh] md:h-[55vh] lg:h-[60vh]">
          <img
            className="absolute object-cover w-full h-full rounded-b-3xl"
            src={WallpaperImage}
            alt=""
          />
          <div className="absolute flex items-center w-full h-full text-center bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/90 rounded-b-3xl">
            <div className="mx-[8vw] flex w-full text-white font-dmSans my-10 xl:px-[5vw]">
              <div className="relative flex w-full space-x-2 mt-36">
                <div className="relative w-full">
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 h-[56px]">
                    {search === "" ? (
                      <label
                        className="px-2 py-1 font-mono text-gray-600 cursor-pointer js-password-label"
                        htmlFor="search"
                      >
                        <img className="h-5" src={SearchIcon} alt="" />
                      </label>
                    ) : (
                      <label
                        className="px-2 py-1 font-mono text-gray-600 cursor-pointer js-password-label"
                        htmlFor="search"
                        onClick={clearSearch}
                      >
                        <img className="h-4" src={CancelIcon} alt="" />
                      </label>
                    )}
                  </div>
                  <input
                    id="search"
                    name="text"
                    placeholder="Buscar por localidad, zona o ciudad"
                    value={search}
                    onChange={handleSearch}
                    type="text"
                    required
                    className="w-full py-[0.95rem] rounded-full font-dmSans text-gray-900 shadow-button bg-white/30 focus:ring-0 focus:border-primary"
                  />
                </div>
                <Menu as={"div"}>
                  <Menu.Button
                    className="p-4 rounded-full bg-white/30"
                    onClick={handleShowFilters}
                  >
                    <img className="h-6" src={FiltersIcon} alt="" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items>
                      <div className="absolute right-0 z-40 w-64 h-auto p-2 ml-0 text-black bg-white border md:w-96 border-primary shadow-card rounded-3xl top-3 w">
                        <div>
                          <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                            <div className="min-[480px]:w-full">
                              <div className="min-[480px]:w-full">
                                <Select
                                  data={categories}
                                  selected={selectedCategory}
                                  onChange={handleCategoryChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                            <div className="min-[480px]:w-full">
                              <div className="min-[480px]:w-full">
                                <div>
                                  <p className="mt-2 text-black font-dmSans">
                                    Ambientes
                                  </p>
                                  <div className="flex justify-center gap-2 mt-2">
                                    {[1, 2, 3, 4, 5, "+6"].map(
                                      (environments) => (
                                        <button
                                          key={environments}
                                          onClick={() =>
                                            handleEnvironmentsChange(
                                              environments
                                            )
                                          }
                                          className={`w-12 py-1 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover ${
                                            selectedEnvironments ===
                                            environments
                                              ? "bg-primaryHover"
                                              : ""
                                          }`}
                                        >
                                          {environments}
                                        </button>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                            <div className="min-[480px]:w-full">
                              <div className="min-[480px]:w-full">
                                <div>
                                  <p className="mt-2 text-black font-dmSans">
                                    Habitaciones
                                  </p>
                                  <div className="flex justify-center gap-2 my-2">
                                    {[1, 2, 3, 4, 5, "+6"].map((rooms) => (
                                      <button
                                        key={rooms}
                                        onClick={() => handleRoomsChange(rooms)}
                                        className={`w-12 py-1 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover ${
                                          selectedRooms === rooms
                                            ? "bg-primaryHover"
                                            : ""
                                        }`}
                                      >
                                        {rooms}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <button
                            className={`w-full py-1 text-white rounded-full ${
                              isFilterButtonDisabled()
                                ? "bg-gray-300"
                                : "bg-primaryHover"
                            } font-dmSans ${
                              isFilterButtonDisabled()
                                ? "cursor-not-allowed"
                                : "hover:bg-primary"
                            }`}
                            onClick={handleFilter}
                            disabled={isFilterButtonDisabled()}
                          >
                            Filtrar
                          </button>
                          <button
                            className="w-full py-1 mt-2 text-gray-800 bg-white border border-gray-300 rounded-full font-dmSans hover:bg-primaryHover hover:text-white"
                            onClick={clearFilters}
                          >
                            Limpiar filtros
                          </button>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <CustomSpinner />
        ) : (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={properties.length}
            next={isSearchOrFilterActive ? null : nextProperties}
            hasMore={!isSearchOrFilterActive && hasMore}
            loader={<CustomSpinner />}
            endMessage={
              isSearchOrFilterActive ? null : (
                <p style={{ textAlign: "center" }}>
                  <b>Las has visto todas!</b>
                </p>
              )
            }
          >
            <MagicMotion>
              <div className="my-10 mx-[5vw] md:my-24 xl:px-[5vw]">
                <div className="grid gap-10 grid-cols md:grid-cols-2 lg:grid-cols-3 lg:gap-x-7">
                  {render && render.length > 0 ? (
                    render.map((property, i) => (
                      <PropertyCard key={property.id} property={property} />
                    ))
                  ) : (
                    <div className="flex items-center justify-center col-span-2 md:col-span-1 lg:col-span-3">
                      <p className="text-center text-gray-500">
                        No hay propiedades para listar aún.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </MagicMotion>
          </InfiniteScroll>
        )}
      </div>
    </section>
  );
}

export default Properties;
