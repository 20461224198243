import { Link } from "react-router-dom";
import DW from "../utils/images/LogoVoid.png";
import Facebook from "../utils/icons/Facebook.svg";
import Linkedin from "../utils/icons/Linkedin.svg";
import Instagram from "../utils/icons/Instagram.svg";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="border-t bg-gray-800">
      <div className="mx-[5vw] pt-6 md:pt-8">
        <div className="md:flex md:items-center md:justify-between xl:mx-[4.5vw]">
          <div className="md:w-[40vw]">
            <Link to="/">
              <img
                className="my-6"
                src={DW}
                alt="Dwilliams"
                style={{ maxWidth: "60px" }}
              />
            </Link>
            <p className="text-white font-dmSans md:text-lg">
              Tu confianza es nuestra prioridad en cada paso del camino
              inmobiliario
            </p>
            <div className="flex items-center my-5 space-x-3">
              <a
                href="https://www.facebook.com/dwilliamsbr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="border border-gray-200 shadow-button rounded-full flex w-[1.875rem] h-[1.875rem] justify-center items-center sm:w-[2.25rem] sm:h-[2.25rem]">
                  <img
                    className="w-[0.875rem] h-[1.25rem] sm:w-[1rem] sm:h-[1.375rem]"
                    src={Facebook}
                    alt=""
                  />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/86424565/admin/feed/posts/?feedType=following"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="border border-gray-200 shadow-button rounded-full flex w-[1.875rem] h-[1.875rem] justify-center items-center sm:w-[2.25rem] sm:h-[2.25rem]">
                  <img
                    className="w-[0.875rem] h-[1.25rem] sm:w-[1rem] sm:h-[1.375rem]"
                    src={Linkedin}
                    alt=""
                  />
                </div>
              </a>
              <a
                href="https://www.instagram.com/dwilliamsbienesraices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="border border-gray-200 shadow-button rounded-full flex w-[1.875rem] h-[1.875rem] justify-center items-center sm:w-[2.25rem] sm:h-[2.25rem]">
                  <img
                    className="w-[0.875rem] h-[1.25rem] sm:w-[1rem] sm:h-[1.375rem]"
                    src={Instagram}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="min-[480px]:flex min-[480px]:space-x-32 md:space-x-24">
            <div className="my-8 font-semibold font-dmSans">
              <h2 className="mb-4 text-lg md:text-xl text-white">Páginas</h2>
              <ul className="flex flex-col text-slate-300 items-start space-y-2 font-medium text-gray-600 text-md sm:mb-0">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/properties" className="hover:underline">
                    Propiedades
                  </Link>
                </li>
                <li>
                  <Link to="/sale" className="hover:underline">
                    En Venta
                  </Link>
                </li>
                <li>
                  <Link to="/rent" className="hover:underline">
                    Alquileres
                  </Link>
                </li>
              </ul>
            </div>
            <div className="my-8 font-semibold font-dmSans">
              <h2 className="mb-4 text-lg md:text-xl text-white">Legal</h2>
              <ul className="flex flex-col items-start text-slate-300 space-y-2 font-medium text-gray-600 text-md sm:mb-0">
                <li>
                  <Link to="#" className="hover:underline">
                    License
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:underline">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:underline">
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-0 lg:mt-8" />
        <span className="block my-5 text-sm text-center text-gray-600">
          © {year} D' Williams. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
