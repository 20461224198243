import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";

const useAreas = () => {
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const areasCollection = collection(db, "areas");
        const areasSnapshot = await getDocs(areasCollection);
        const areasData = areasSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAreas(areasData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching areas:", error);
        setLoading(false);
      }
    };

    fetchAreas();
  }, []);

  return { areas, loading };
};

export default useAreas;
