import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot, // Importar onSnapshot
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import Swal from "sweetalert2";

function useAgentes() {
  const [agentes, setAgentes] = useState([]);

  const handleSelect = async (consultaId, agentName) => {
    const consultaDocRef = doc(db, "customerQueries", consultaId);
    try {
      await updateDoc(consultaDocRef, {
        agentName,
      });
      await Swal.fire({
        icon: "success",
        title: "Agente actualizado",
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Hubo un error al actualizar el agente.`,
      });
    }
  };

  const addAgente = async (agentName, agentEmail) => {
    try {
      const docRef = await addDoc(collection(db, "users"), {
        agentName: agentName,
        email: agentEmail,
      });
      const newAgent = {
        id: docRef.id,
        agentName: agentName,
        email: agentEmail,
      };
      setAgentes([...agentes, newAgent]);
    } catch (error) {
      console.error("Error adding agent: ", error);
    }
  };

  const updateAgente = async (agentId, newAgenteName, newAgenteEmail) => {
    try {
      const usersDocRef = doc(db, "users", agentId);
      await setDoc(usersDocRef, {
        agentName: newAgenteName,
        email: newAgenteEmail,
      });
    } catch (error) {
      console.error("Error updating agent: ", error);
    }
  };

  const deleteAgent = async (agentId) => {
    try {
      const usersDocRef = doc(db, "users", agentId);
      await deleteDoc(usersDocRef);
    } catch (error) {
      console.error("Error deleting agent: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (querySnapshot) => {
      const agentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgentes(agentsData);
    });

    return () => unsubscribe();
  }, []);

  return { agentes, handleSelect, addAgente, updateAgente, deleteAgent };
}

export default useAgentes;
