import { Link } from "react-router-dom";
import Environment from "../utils/icons/Environments.svg";
import Room from "../utils/icons/Room.svg";

const PropertyCard = ({ property, i, length, lastElementRef }) => {
  return (
    <div
      className="w-full h-auto border rounded-3xl shadow-card"
      key={i}
      ref={i === length - 1 ? lastElementRef : null}
    >
      <img
        className="w-full h-[53.5vw] rounded-t-3xl object-cover min-[480px]:h-[48.5vw] sm:h-[55.2vw] md:h-[26.9vw] lg:h-[17.8vw]"
        src={property.images[0]}
        loading="lazy"
        alt={`Portada de ${property.title}`}
      />
      <div className="px-6 pt-8 pb-7 font-dmSans">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-xl font-semibold">{property.title}</h3>
            <div className="flex items-center my-2 text-base text-gray-600">
              <img
                className="h-4 mr-2"
                src="https://www.svgrepo.com/show/512655/pin-rounded-circle-620.svg"
                loading="lazy"
                alt={`Portada de ${property.title}`}
              />
              {property.city}, {property.country}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap my-5">
          {property?.environments && (
            <div className="w-auto flex items-center border rounded-full py-[0.625rem] px-4 text-sm mr-3">
              <img className="mr-2" src={Environment} alt="" />
              {property.environments} Ambientes
            </div>
          )}
          {property?.rooms && (
            <div className="w-auto flex items-center border border-gray-200 rounded-full py-[0.625rem] px-4 text-sm">
              <img className="mr-2" src={Room} alt="" /> {property.rooms}{" "}
              Habitaciones
            </div>
          )}
        </div>
        {/* <div className="flex flex-wrap my-5">
          {property.environments !== 0 && (
            <div className="w-auto flex items-center border rounded-full py-[0.625rem] px-4 text-sm mr-3">
              <img className="mr-2" src={Environment} alt="" />
              {property.environments} Environments
            </div>
          )}
          {property.rooms !== 0 && (
            <div className="w-auto flex items-center border border-gray-200 rounded-full py-[0.625rem] px-4 text-sm">
              <img className="mr-2" src={Room} alt="" /> {property.rooms} Rooms
            </div>
          )}
        </div> */}
        <hr className="mt-8 mb-6 border-t border-gray-300" />
        <div className="min-[480px]:w-full min-[480px]:flex min-[480px]:flex-wrap min-[480px]:items-center min-[480px]:justify-between">
          <div className="flex items-center mb-4 min-[480px]:mb-0">
            <div className="text-xl font-semibold">
              {property.currency === "ARG" ? "$" : "USD "}
              {property.price}
            </div>
            <div
              className={
                property.operation === "rent"
                  ? "text-sm text-gray-400 ml-1"
                  : "hidden"
              }
            >
              /mes
            </div>
          </div>
          <div className="w-full min-[480px]:w-auto">
            <Link to={`/properties/${property.id}`}>
              <button className="block bg-primary w-full py-[0.81rem] pl-3 pr-4 rounded-full text-white font-semibold hover:bg-primaryHover min-[480px]:w-auto min-[480px]:px-[1.63rem] md:py-4 md:px-6 md:bg-primary md:w-full md:p-0">
                Ver más
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
