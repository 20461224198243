import React, { useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "../commons/Spinner";

const UpdateModal = ({
  updateFunction,
  handleCloseModal,
  itemId,
  itemName,
  data,
  additionalInputLabel,
  type,
}) => {
  const [newName, setNewName] = useState(itemName);
  const [newEmail, setNewEmail] = useState(additionalInputLabel);
  const [isLoading, setIsLoading] = useState(false);

  const displaySwal = (icon, text) => {
    Swal.fire({
      icon,
      text,
    });
  };

  const handleNewName = (event) => {
    setNewName(event.target.value);
  };
  const handleNewEmail = (event) => {
    setNewEmail(event.target.value);
  };
  const handleUpdate = async () => {
    const trimText = newName.trim();

    if (trimText === "" || newEmail === "") {
      displaySwal("warning", "No puedes guardar un campo vacío.");
      return;
    }

    const existsMessage = additionalInputLabel
      ? "Ese agente ya existe."
      : "Esa categoría ya existe.";

    if (categoryExists(trimText) || agentExists(trimText)) {
      displaySwal("warning", existsMessage);
      return;
    }

    setIsLoading(true);

    try {
      await updateFunction(itemId, newName, newEmail);
      const successMessage = additionalInputLabel
        ? "Agente agregado exitosamente!"
        : "Categoría agregada exitosamente!";
      displaySwal("success", successMessage);
      handleCloseModal();
    } catch (error) {
      const errorMessage = additionalInputLabel
        ? "Se produjo un error al agregar el agente."
        : "Se produjo un error al agregar la categoría.";
      displaySwal("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const categoryExists = (text) =>
    data.some(
      (item) => item.name && item.name.toLowerCase() === text.toLowerCase()
    );

  const agentExists = (text) =>
    data.some(
      (item) =>
        item.agentName && item.agentName.toLowerCase() === text.toLowerCase()
    );

  const modalStyles = {
    container:
      "fixed inset-0 z-50 flex items-center justify-center min-h-screen animated fadeIn faster",
    background: "absolute inset-0 bg-black opacity-70",
    content:
      "w-full max-w-sm relative mx-2 my-auto p-6 shadow-lg bg-white rounded-3xl flex flex-col gap-5",
    text: "text-center p-5 flex items-center space-x-4 my-2",
    image: "h-20 mx-auto mb-4",
    heading: "font-semibold font-dmSans",
    buttonContainer: "flex justify-center space-x-2",
    buttonPrimary:
      "w-auto px-6 py-3 text-base font-dmSans text-white text-center rounded-full bg-primary hover-bg-primaryHover focus-ring-0",
    buttonSecondary:
      "w-auto px-6 py-3 text-base font-dmSans text-center text-black bg-white border border-gray-200 rounded-full hover-bg-primary hover-text-white focus-ring-0",
  };

  return (
    <div className={modalStyles.container}>
      <div className={modalStyles.background} />
      <div className={modalStyles.content}>
        <h2 className={modalStyles.heading}>Editar {type}</h2>
        <input
          type="text"
          value={newName}
          onChange={handleNewName}
          placeholder={`Nuevo nombre de ${type}`}
        />
        {additionalInputLabel && (
          <input
            type="email"
            value={newEmail}
            onChange={handleNewEmail}
            placeholder={`Nuevo email de ${type}`}
          />
        )}
        <div className={modalStyles.buttonContainer}>
          <button
            className={modalStyles.buttonPrimary}
            onClick={handleUpdate}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Guardar"}
          </button>
          <button
            className={modalStyles.buttonSecondary}
            onClick={handleCloseModal}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
