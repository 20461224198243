import React, { useState, useEffect } from "react";
import usePropertiesPanel from "../hooks/usePropertiesPanel";
import { Spinner } from "./Spinner";

const UpdateImagesModal = ({
  images,
  onUpdateImages,
  onClose,
  setChangesMade,
}) => {
  const [selectedImages, setSelectedImages] = useState(images);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [fileMessage, setFileMessage] = useState("Ninguna imagen seleccionada");
  const { uploadImagesToFirebase, loading } = usePropertiesPanel();

  const updateFileMessage = () => {
    const numImages = selectedImages.length;
    setFileMessage(
      numImages === 0
        ? "Ninguna imagen seleccionada"
        : numImages === 1
        ? "1 imagen seleccionada"
        : `${numImages} imágenes seleccionadas`
    );
  };

  useEffect(() => {
    updateFileMessage();
  }, [selectedImages]);

  const handleSaveImages = async () => {
    const existingImageURLs = selectedImages.filter(
      (image) => typeof image === "string"
    );
    const newImages = selectedImages.filter(
      (image) => typeof image !== "string"
    );
    const newImageURLs = await uploadImagesToFirebase(newImages);
    const updatedImages = [...existingImageURLs, ...newImageURLs];
    if (
      selectedImageIndex !== null &&
      selectedImageIndex !== 0 &&
      selectedImageIndex < updatedImages.length
    ) {
      const selectedImage = updatedImages[selectedImageIndex];
      updatedImages.splice(selectedImageIndex, 1);
      updatedImages.unshift(selectedImage);
    }
    onUpdateImages(updatedImages);
    onClose();
  };

  const handleSelectImage = (index) => {
    if (index !== selectedImageIndex) {
      setSelectedImageIndex(index);
    }
  };

  const handleImageRemoval = (imageToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove)
    );
    setChangesMade(true);
  };

  const handleCloseModal = () => {
    onClose();
  };

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
    setSelectedImageIndex(null);
    setChangesMade(true);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen overflow-auto animated fadeIn faster">
      <div className="absolute inset-0 bg-black opacity-70" />
      <div className="relative flex flex-col w-11/12 gap-5 p-6 m-auto overflow-x-auto bg-white shadow-lg lg:w-3/4 rounded-3xl">
        <h1>Editar Imágenes</h1>
        <div className="relative w-full">
          <input
            className="absolute inset-0 h-full border opacity-0 cursor-pointer rounded-3xl border-primary "
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            multiple
          />
          <button
            type="button"
            className="w-full px-4 py-2 font-bold text-white rounded bg-primary "
          >
            {fileMessage}
          </button>
        </div>
        <div className="flex items-center justify-start gap-4 overflow-x-auto">
          {selectedImages.length === 0 ? (
            <span className="text-sm text-center">
              Ninguna imagen seleccionada
            </span>
          ) : (
            selectedImages.map((image, index) => (
              <div
                key={index}
                className={`relative cursor-pointer min-h-[180px] min-w-[180px] h-44 w-44 border-2 ${
                  index === selectedImageIndex ||
                  (index === 0 && selectedImageIndex === null)
                    ? "border-primary"
                    : ""
                }`}
                onClick={() => handleSelectImage(index)}
              >
                <img
                  src={
                    typeof image === "string"
                      ? image
                      : URL.createObjectURL(image)
                  }
                  alt={`Imagen seleccionada ${index}`}
                  className="object-cover w-full h-full thumbnail-style"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-white bg-red-500"
                  onClick={() => handleImageRemoval(image)}
                >
                  X
                </button>
              </div>
            ))
          )}
        </div>
        <div className="flex justify-center gap-4">
          <button
            onClick={handleSaveImages}
            type="button"
            className="w-auto px-6 py-3 text-base text-center text-white rounded-full font-dmSans bg-primary hover:bg-primaryHover focus:ring-0"
          >
            {loading ? <Spinner /> : "Guardar"}
          </button>
          <button
            type="button"
            onClick={handleCloseModal}
            className="w-auto px-6 py-3 text-base text-center text-black bg-white border border-gray-200 rounded-full font-dmSans hover:bg-primary hover:text-white focus:ring-0"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateImagesModal;
