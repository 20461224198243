import React from "react";
import { Link } from "react-router-dom";
import DWTitle from "../utils/images/dwTitleLogo.png";
import Environment from "../utils/icons/Environments.svg";
import Room from "../utils/icons/Room.svg";
import Top_Card from "../utils/icons/TopCard.svg";
import Bottom_Card from "../utils/icons/BottomCard.svg";
import ArrowLeft from "../utils/icons/ArrowLeft.svg";
import ArrowRight from "../utils/icons/ArrowRight.svg";
import Merlo from "../utils/images/merlo.jpeg";
import potrero from "../utils/images/potrero.jpg";
import florida from "../utils/images/florida.jpg";
import client1 from "../utils/images/juanGomez.png";
import client2 from "../utils/images/diegoTroentle.png";
import client3 from "../utils/images/cesar.png";
import wallpaperImage from "../utils/images/sierras.jpg";
import useProperties from "../hooks/useProperties";

const Home = () => {
  const { properties, isLoading } = useProperties({
    queryParameter: "featured",
  });
  const widthPx = window.innerWidth < 640 ? window.innerWidth - 8 : 380;
  const scrollLeft = () =>
    (document.getElementById("content").scrollLeft -= widthPx);
  const scrollRight = () =>
    (document.getElementById("content").scrollLeft += widthPx);

  const widthPx2 = window.innerWidth < 1024 ? window.innerWidth - 8 : 728 + 100;
  const scrollLeft2 = () =>
    (document.getElementById("content2").scrollLeft -= widthPx2);
  const scrollRight2 = () =>
    (document.getElementById("content2").scrollLeft += widthPx2);

  return (
    <>
      <section
        className="h-screen w-full bg-cover bg-center bg-fixed sm:h-screen md:h-[110vh] "
        style={{
          backgroundImage: `url(${wallpaperImage})`,
        }}
      >
        <div className="h-screen w-full backdrop-opacity-10 backdrop-invert bg-transparent bg-gradient-to-b from-transparent to-black sm:h-screen md:h-[110vh]">
          <div className="h-screen flex items-center mx-[2.5vw] pt-[33vw] sm:h-screen sm:pt-[22vw] md:h-[110vh] md:pt-[15vh] ">
            <div className="flex flex-wrap justify-center w-full ">
              <img src={DWTitle} alt="" className="px-4 lg:w-1/3" />
              <p className="text-white text-center font-dmSans px-3 py-6 md:text-lg md:px-[15vw] lg:px-[22vw]">
                Descubre tu hogar en San Luis: <br />
                Más de tres décadas construyendo sueños a través de bienes
                raíces.
              </p>
              <div className="flex flex-col justify-center w-full px-3 py-6 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Link to="/properties">
                  <button className="block bg-primary w-full py-[1.0315rem] rounded-full text-base font-dmSans text-white hover:bg-primaryHover sm:w-auto sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.25rem] md:px-8">
                    Explorar propiedades
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h-auto mb-[1vh] bg-slate-100">
        <div className="pt-24 mx-[5vw] mb-[4vh] space-y-8 md:flex md:flex-wrap md:items-center md:space-y-0 md:justify-between xl:mx-[10.5vw]">
          <p className="text-2xl font-semibold text-center font-dmSans md:text-3xl lg:text-4xl">
            Explorá nuestras propiedades
          </p>
          <Link
            className="block shadow-button bg-white border border-gray-200 text-center w-full py-[1rem] px-6 rounded-full text-base font-dmSans text-gray-900 hover:bg-primary hover:text-white min-[480px]:w-auto sm:w-auto sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.375rem] md:px-9"
            to="/properties"
          >
            <button>Ver todas</button>
          </Link>
        </div>
        <div className="pt-8 md:pt-10 lg:pt-20 md:pb-20 mx-[5vw] xl:mx-[10.5vw]">
          <div
            id="content"
            className="snap-x p-1 flex items-center md:overflow-hidden justify-start overflow-x-auto scroll-smooth scrollbar-hide space-x-[10vw] sm:space-x-[3vw] lg:p-1 lg:space-x-[2vw] "
          >
            {isLoading ? (
              <div
                role="status"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#641315"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#641315"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : properties && properties.length > 0 ? (
              <>
                <button
                  onClick={scrollLeft}
                  className="absolute left-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:left-1 lg:left-[1vw] xl:p-5 xl:left-[8.2vw]"
                >
                  <img className="h-5" src={ArrowLeft} alt="" />
                </button>
                {properties.map((property, i) => (
                  <div className="snap-center sm:snap-start">
                    <div className="shadow-card w-[87vw] bg-white h-auto border rounded-3xl  min-[480px]:w-[24.375rem]">
                      <img
                        className="w-full h-[53.5vw] rounded-t-3xl object-cover min-[480px]:h-[14.5rem]"
                        src={property.images[0]}
                        alt=""
                      />
                      <div className="px-6 pt-8 pb-7 font-dmSans">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="text-xl font-semibold">
                              {property.title}
                            </h3>
                            <div className="flex items-center my-2 text-base text-gray-600">
                              <img
                                className="h-4 mr-2"
                                src="https://www.svgrepo.com/show/512655/pin-rounded-circle-620.svg"
                                alt=""
                              />
                              {property.city}, {property.country}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap my-5">
                          <div className="w-auto flex items-center border rounded-full py-[0.625rem] px-4 text-sm mr-3">
                            <img className="mr-2" src={Environment} alt="" />
                            {property.environments} Ambientes
                          </div>
                          <div className="w-auto flex items-center border border-gray-200 rounded-full py-[0.625rem] px-4 text-sm">
                            <img className="mr-2" src={Room} alt="" />
                            {property.rooms} Habitaciones
                          </div>
                        </div>
                        <hr className="mt-8 mb-6 border-t border-gray-300" />
                        <div className="min-[480px]:w-full min-[480px]:flex min-[480px]:flex-wrap min-[480px]:items-center min-[480px]:justify-between">
                          <div className="flex items-center mb-4 min-[480px]:mb-0">
                            <div className="text-xl font-semibold">
                            {property.currency === "ARG" ? "$" : "USD "}{property.price}
                            </div>
                            <div
                              className={
                                property.operation === "rent"
                                  ? "text-sm text-gray-400 ml-1"
                                  : "hidden"
                              }
                            >
                              /mes
                            </div>
                          </div>
                          <div className="w-full min-[480px]:w-auto">
                            <Link to={`/properties/${property.id}`}>
                              <button className="block bg-primary w-full py-[0.81rem] pl-3 pr-4 rounded-full text-white font-semibold hover:bg-primaryHover min-[480px]:w-auto min-[480px]:px-[1.63rem] md:py-4 md:px-6 md:bg-primary md:w-full md:p-0">
                                Ver mas
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  onClick={scrollRight}
                  className="absolute right-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:right-1 lg:right-[1vw] xl:p-5 xl:right-[8.2vw]"
                >
                  <img className="h-5" src={ArrowRight} alt="" />
                </button>
              </>
            ) : (
              <p>No hay propiedades listadas aún.</p>
            )}
          </div>
        </div>
      </section>
      <section className="h-auto">
        <div className="py-16 mx-[5vw] lg:py-44">
          <div className="relative h-auto bg-primary rounded-3xl xl:mx-[4.5vw] overflow-hidden">
            <img
              className="w-[15rem] absolute -top-28 -left-28 md:w-[18rem]"
              src={Top_Card}
              alt=""
            />
            <img
              className="w-[10rem] absolute -bottom-24 -right-24 min-[480px]:-bottom-16 min-[480px]:-right-16 sm:w-[40vw] sm:-bottom-14 sm:-right-14"
              src={Bottom_Card}
              alt=""
            />
            <div className="py-14 px-8 font-dmSans md:py-[4.5rem] md:px-[3.375rem] lg:py-[5.875rem] lg:px-[5.375rem]">
              <h2 className="text-2xl font-semibold text-white mb-3 min-[480px]:text-[1.625rem] md:text-3xl md:w-[36.5rem] lg:text-[2.75rem] lg:leading-tight">
                Recarga tus energías en el verde San Luis
              </h2>
              <p className="text-base text-white md:text-lg md:w-[36.5rem]">
                Encuentra tu refugio en medio de la naturaleza y renueva tu
                vida.
              </p>
              <div className="w-full flex flex-col justify-center pt-6 space-y-4 min-[480px]:flex-row min-[480px]:space-y-0 min-[480px]:space-x-3 min-[480px]:justify-start sm:pt-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <Link to={`/properties`}>
                  <button className="block bg-white w-full py-[1.0315rem] rounded-full text-base font-dmSans text-primary font-semibold hover:text-black min-[480px]:w-auto min-[480px]:px-6 sm:w-auto sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.25rem] md:px-8">
                    Explorar propiedades
                  </button>
                </Link>
                <Link to={`/properties`}>
                <button
                  className="block shadow-button bg-primary border border-white w-full py-[1.0315rem] rounded-full text-base font-dmSans text-white hover:bg-white hover:text-black min-[480px]:w-auto min-[480px]:px-6 sm:w-auto sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.25rem] md:px-8"
                  href="/contact"
                >
                  Nosotros
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h-auto">
        <div className="mx-[5vw] text-center">
          <h2 className="text-2xl font-semibold font-dmSans mb-3 sm:px-[15vw] sm:mb-5 md:text-3xl lg:text-4xl lg:px-[25vw]">
            Descubrí San Luis
          </h2>
          <p className="text-gray-600 font-dmSans sm:px-[10vw]md:text-lg md:px-[10vw] md:text-lg lg:text-lg lg:px-[22vw]">
            Desde las imponentes Sierras hasta la serena llanura, sumérgete en
            la autenticidad de esta provincia en cada rincón que recorras.
          </p>
          <div className="xl:mx-[4.5vw]">
            <div className="grid w-full grid-cols-1 gap-6 py-10 md:grid-cols-3 md:py-12 ">
              <div className="relative h-[121.875vw] md:h-[39.064vw] xl:h-[60vh] hover:-translate-y-2 hover:transition hover:duration-300">
                <img
                  className="absolute object-cover w-full h-full rounded-3xl"
                  src={Merlo}
                  alt=""
                />
                <div className="absolute w-full h-full bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/70 rounded-3xl">
                  <h3 className="h-full absolute flex px-8 text-white items-end py-12 text-[1.375rem] font-semibold">
                    Merlo
                  </h3>
                </div>
              </div>
              <div className="relative h-[121.875vw] md:h-[39.064vw] xl:h-[60vh] hover:-translate-y-2 hover:transition hover:duration-300">
                <img
                  className="absolute object-cover w-full h-full rounded-3xl"
                  src={potrero}
                  alt=""
                />
                <div className="absolute w-full h-full bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/70 rounded-3xl">
                  <h3 className="h-full absolute flex px-8 text-white items-end py-12 text-[1.375rem] font-semibold">
                    Potrero de los Funes
                  </h3>
                </div>
              </div>
              <div className="relative h-[121.875vw] md:h-[39.064vw] xl:h-[60vh] hover:-translate-y-2 hover:transition hover:duration-300">
                <img
                  className="absolute object-cover w-full h-full rounded-3xl"
                  src={florida}
                  alt=""
                />
                <div className="absolute w-full h-full bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/70 rounded-3xl">
                  <h3 className="h-full absolute flex px-8 text-white items-end py-12 text-[1.375rem] font-semibold">
                    La Florida
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h-auto">
        <div className="mx-[5vw] pt-16 pb-8 lg:pb-40 xl:mx-[10.5vw]">
          <h2 className="text-2xl font-semibold font-dmSans mb-3 text-center sm:px-[15vw] sm:mb-5 md:text-3xl lg:text-4xl lg:px-[25vw]">
            Lo que nuestro clientes dicen
          </h2>
          <p
            className="text-gray-600 font-dmSans mb-10
           text-center sm:px-[10vw]md:text-lg md:px-[10vw] md:text-lg lg:text-lg lg:px-[22vw]"
          >
            Nuestros clientes felices son el mejor testimonio de nuestro
            compromiso contigo y tu hogar
          </p>
          <div className="pt-8 md:pt-10 lg:pt-20 md:pb-20">
            <div
              id="content2"
              className="md:overflow-hidden snap-x p-1 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide space-x-[3vw] lg:space-x-[2.5vw] lg:p-1 lg:scroll-pl-1"
            >
              <button
                onClick={scrollLeft2}
                className="absolute left-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:left-1 lg:left-[1vw] xl:p-5 xl:left-[8.2vw]"
              >
                <img className="h-5" src={ArrowLeft} alt="" />
              </button>
              <div className="snap-center lg:snap-start">
                <div className="w-[87vw] h-auto py-10 px-6 border shadow-card rounded-3xl sm:py-[3.125rem] sm:px-[2rem] md:flex md:items-center md:space-x-8 md:px-[4.375rem] lg:w-[728px] lg:px-11">
                  <img
                    className="rounded-full mb-6 sm:w-[17.625rem] md:mb-0 md:w-[14.63rem] lg:w-[17.6rem]"
                    src={client1}
                    alt=""
                    style={{ minWidth: "200px" }}
                  />
                  <div className="font-dmSans">
                    <h3 className="text-xl font-semibold md:text-[1.375rem]">
                      “Inmobiliaria seria y de confianza”
                    </h3>
                    <p className="py-4 text-gray-600 md:text-lg">
                      Inmobiliaria seria, de confianza, y con muchos años de
                      trayectoria en la ciudad de San Luis. Precios lógicos para
                      un mercado bastante complejo como lo es el inmobiliario en
                      los tiempos que se viven...
                    </p>
                    <div>
                      <div className="font-semibold">Juan Eduardo Gomez</div>
                      <div className="text-gray-600">San Luis, ARG</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="snap-center lg:snap-start">
                <div className="w-[87vw] h-auto py-10 px-6 border shadow-card rounded-3xl sm:py-[3.125rem] sm:px-[2rem] md:flex md:items-center md:space-x-8 md:px-[4.375rem] lg:w-[728px] lg:px-11">
                  <img
                    className="rounded-full mb-6 sm:w-[17.625rem] md:mb-0 md:w-[14.63rem] lg:w-[17.6rem]"
                    src={client2}
                    alt=""
                    style={{ minWidth: "200px" }}
                  />
                  <div className="font-dmSans">
                    <h3 className="text-xl font-semibold md:text-[1.375rem]">
                      “Muy confiable”
                    </h3>
                    <p className="py-4 text-gray-600 md:text-lg">
                      Destacada y reconocida inmobiliaria en San Luis. Con años
                      de trayectoria en la administración de bienes raíces.
                      Compra, venta y alquiler de casas...
                    </p>
                    <div>
                      <div className="font-semibold">Diego Troentle</div>
                      <div className="text-gray-600">San Luis, ARG</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="snap-center lg:snap-start">
                <div className="w-[87vw] h-auto py-10 px-6 border shadow-card rounded-3xl sm:py-[3.125rem] sm:px-[2rem] md:flex md:items-center md:space-x-8 md:px-[4.375rem] lg:w-[728px] lg:px-11">
                  <img
                    className="rounded-full mb-6 sm:w-[17.625rem] md:mb-0 md:w-[14.63rem] lg:w-[17.6rem]"
                    src={client3}
                    alt=""
                    style={{ minWidth: "200px" }}
                  />
                  <div className="font-dmSans">
                    <h3 className="text-xl font-semibold md:text-[1.375rem]">
                      “La atención es genial”
                    </h3>
                    <p className="py-4 text-gray-600 md:text-lg">
                      Excelente la administración, los trámites son rápidos y te
                      explican bien las cosas si no sabes mucho del tema.
                    </p>
                    <div>
                      <div className="font-semibold">Cesar Andres Escudero</div>
                      <div className="text-gray-600">San Luis, ARG</div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={scrollRight2}
                className="absolute right-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:right-1 lg:right-[1vw] xl:p-5 xl:right-[8.2vw]"
              >
                <img className="h-5" src={ArrowRight} alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
