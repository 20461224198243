import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ currentPage, onPageChange, totalPages }) => {
  return (
    <ReactPaginate
      previousLabel={
        <span className="flex items-center justify-center w-full h-full">
          Atrás
        </span>
      }
      nextLabel={
        <span className="flex items-center justify-center w-full h-full">
          Siguiente
        </span>
      }
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={"flex justify-center gap-1 p-1 py-4 sm:p-8"}
      pageClassName={
        " px-4 py-2 rounded-full  bg-primary text-white transition duration-300 ease-in-out cursor-pointer hover:bg-primaryHover"
      }
      activeClassName={"bg-primaryHover"}
      previousClassName={`sm:mr-4 w-24 lg:w-32 text-sm md:text-md   text-center px-4 py-2 rounded-full bg-primary text-white `}
      nextClassName={`sm:ml-4 lg:w-32 w-24 text-sm md:text-md  text-center px-4 py-2 rounded-full bg-primary text-white  `}
      disabledClassName={" opacity-50"}
    />
  );
};

export default Pagination;
