import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import Swal from "sweetalert2";

function useConsultas(isActive, sortDate) {
  const [consultas, setConsultas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function calculateSemaphorColor(timestamp) {
    const currentDate = new Date().getTime();
    const timeDifference = currentDate - timestamp;

    const oneDay = 24 * 60 * 60 * 1000;
    const twelveHours = 12 * 60 * 60 * 1000;

    if (timeDifference > oneDay) {
      return "red";
    } else if (timeDifference > twelveHours) {
      return "yellow";
    } else {
      return "green";
    }
  }

  const toggleEstadoConsulta = async (consultaId, resolved) => {
    const consultaDocRef = doc(db, "customerQueries", consultaId);
    try {
      await updateDoc(consultaDocRef, {
        resolved: !resolved,
      });
      await Swal.fire({
        icon: "success",
        title: "Consulta resuelta",
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Hubo un error al actualizar el estado de la consulta.`,
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const consultasRef = collection(db, "customerQueries");
    const consultasQuery = query(
      consultasRef,
      where("resolved", "==", isActive),
      orderBy("timestamp", sortDate ? "asc" : "desc")
    );

    const unsubscribe = onSnapshot(consultasQuery, (snapshot) => {
      const consultasData = [];
      snapshot.forEach((doc) => {
        consultasData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setConsultas(consultasData);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [isActive, sortDate]);

  return { consultas, isLoading, calculateSemaphorColor, toggleEstadoConsulta };
}

export default useConsultas;
