import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const PanelAdmin = () => {
  const location = useLocation();
  const navItems = ["Propiedades", "Consultas", "Usuarios", "Categorias"];
  const isRootPanelAdmin = location.pathname === "/panelAdmin";

  return (
    <div>
      <div className="h-auto pt-32 mx-[7vw] sm:pt-36 md:mx-[4vw] lg:mx-[6vw] xl:mx-[12vw]">
        <div className="w-full mb-4 text-lg text-gray-600 font-dmSans ">
          <h1 className="text-center">Panel Administrador</h1>
        </div>
        <div className="md:flex md:space-x-2">
          <div className="w-full h-auto rounded-3xl font-dmSans md:justify-center">
            <nav className="flex flex-wrap justify-center gap-1 md:justify-start">
              {navItems.map((element) => (
                <NavLink
                  key={element.toLowerCase()}
                  to={element.toLowerCase()}
                  className={({ isActive }) =>
                    isActive || (isRootPanelAdmin && element === "Propiedades")
                      ? "bg-primary text-white h-10 px-4 py-2  rounded-full"
                      : "border border-primary text-black h-10 px-4 py-2  rounded-full"
                  }
                >
                  {element}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PanelAdmin;
