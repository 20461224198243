import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Delete from "../utils/icons/Delete.svg";
import Edit from "../utils/icons/Edit.svg";
import Add from "../utils/icons/Add.svg";
import DeleteProp from "../commons/DeleteProp";
import usePropertiesPanel from "../hooks/usePropertiesPanel";
import CustomSpinner from "../commons/customSpinner";
import Swal from "sweetalert2";
import SwitchButton from "../commons/SwitchButton";
import Pagination from "../commons/Pagination";
import Search from "../utils/icons/Search.svg";
import Cancel from "../utils/icons/Cancel.svg";
import Filters from "../utils/icons/Filters.svg";
import useCategories from "../hooks/useCategories";
import { MagicMotion } from "react-magic-motion";
import Select from "../commons/Select";
import { Menu, Transition } from "@headlessui/react";
export const PropertiesPanel = () => {
  const [action, setAction] = useState("");
  const [propId, setPropId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRooms, setSelectedRooms] = useState("");
  const [selectedEnvironments, setSelectedEnvironments] = useState("");
  const { categories } = useCategories();
  const {
    properties,
    updateFeaturedStatus,
    setIsActive,
    isActive,
    getAllProperties,
    deleteProperty,
    loading,
  } = usePropertiesPanel();
  const [render, setRender] = useState([]);

  useEffect(() => {
    setRender(properties);
  }, [properties]);

  const handleDeleteProp = async () => {
    try {
      await deleteProperty(propId);
      await Swal.fire({
        icon: "success",
        title: "Propiedad eliminada.",
      });
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: "Error al eliminar la propiedad.",
      });
    }
  };
  const isFilterButtonDisabled = () => {
    return (
      !selectedCategory && !selectedRooms && !selectedEnvironments && !search
    );
  };
  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearch(searchText);
    const searchProperties = properties.filter((property) => {
      const normalizedTitle = normalizeText(property.title);
      const normalizedCity = normalizeText(property.city);
      const normalizedCountry = normalizeText(property.country);
      const normalizedState = normalizeText(property.state);
      const searchMatch =
        normalizedTitle.includes(searchText) ||
        normalizedCity.includes(searchText) ||
        normalizedCountry.includes(searchText) ||
        normalizedState.includes(searchText);
      const environmentMatch =
        selectedEnvironments === "" ||
        property.environments === Number(selectedEnvironments);
      return searchMatch && environmentMatch;
    });
    setRender(searchProperties);
  };

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const handleFilter = () => {
    const updatedFilteredProperties = properties.filter((property) => {
      const categoryMatch =
        selectedCategory === "" || property.category === selectedCategory;

      const roomsMatch =
        selectedRooms === "" ||
        (selectedRooms === "+6"
          ? property.rooms > 6
          : property.rooms === Number(selectedRooms));

      const environmentsMatch =
        selectedEnvironments === "" ||
        (selectedEnvironments === "+6"
          ? property.environments > 6
          : property.environments === Number(selectedEnvironments));

      return categoryMatch && roomsMatch && environmentsMatch;
    });

    setRender(updatedFilteredProperties);
  };

  const handleMenuFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleEnvironmentsChange = (selectedValue) => {
    setSelectedEnvironments((prevSelected) =>
      prevSelected === selectedValue ? "" : selectedValue
    );
  };

  const handleRoomsChange = (selectedValue) => {
    setSelectedRooms((prevSelected) =>
      prevSelected === selectedValue ? "" : selectedValue
    );
  };

  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const totalPages = Math.ceil(render.length / itemsPerPage);
  const currentProperties = render.slice(
    startIndex < render.length ? startIndex : 0,
    endIndex
  );

  const clearSearch = () => {
    setSearch("");
    setRender(properties);
  };

  const clearFilters = () => {
    setSelectedCategory("");
    setSelectedEnvironments("");
    setSelectedRooms("");
    setSearch("");
    setRender(properties);
  };
  const handleCheckboxChange = async (propertyId, isChecked) => {
    const allProperties = await getAllProperties();
    const MAX_FEATURED_PROPERTIES = 6;
    if (
      isChecked &&
      allProperties.filter((prop) => prop.featured).length >=
        MAX_FEATURED_PROPERTIES
    ) {
      Swal.fire({
        icon: "info",
        title: "Limite alcanzado",
        text: "Lo siento, solo puedes agregar hasta 6 propiedades destacadas. Si deseas añadir una más, primero deberás eliminar alguna de las existentes.",
      });
      return;
    }
    try {
      await updateFeaturedStatus(propertyId, isChecked);

      if (isChecked) {
        Swal.fire({
          icon: "success",
          title: "Propiedad destacada",
          text: "La propiedad ha sido marcada como destacada.",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Propiedad quitada de destacada",
          text: "La propiedad ha sido quitada de destacada.",
        });
      }
    } catch (error) {
      console.error("Error al actualizar el estado destacado: ", error);
    }
  };

  const getPropId = (id) => {
    setPropId(id);
    return setAction("deleteProp");
  };
  const closeModal = () => {
    return setAction("");
  };

  return (
    <div>
      <div className="flex flex-col items-baseline justify-between mt-4 text-center sm:text-start sm:flex-row sm:mt-0">
        {totalPages > 0 && (
          <span className="w-full ">
            Paginas ({currentPage}/{totalPages})
          </span>
        )}
        <div className="flex justify-between w-full mt-4 sm:justify-end sm:gap-7">
          <SwitchButton
            type="propiedades"
            labelActive="Activas"
            labelInactive="Todas"
            isActive={isActive}
            setIsActive={setIsActive}
          />
          <Link to={`/properties/create`}>
            <button className="flex items-center px-4 py-2 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover">
              Nueva
              <img className="h-5 ml-2" src={Add} alt="" />
            </button>
          </Link>
        </div>
      </div>

      <div className="relative flex items-center w-full h-full my-3 text-center ">
        <div className="mx-[8vw] flex w-full text-white font-dmSans  xl:px-[5vw]">
          <div className="flex w-full space-x-2">
            <div className="relative w-full rounded-3xl">
              <div className="absolute inset-y-0 right-0 flex items-center px-2 h-[56px] bg-primary rounded-r-3xl">
                {search === "" ? (
                  <label
                    className="px-2 py-1 font-mono text-gray-600 cursor-pointer js-password-label"
                    htmlFor="search"
                  >
                    <img className="w-5" src={Search} alt="" />
                  </label>
                ) : (
                  <label
                    className="px-2 py-1 font-mono text-gray-600 cursor-pointer js-password-label"
                    htmlFor="search"
                    onClick={clearSearch}
                  >
                    <img className="w-5" src={Cancel} alt="" />
                  </label>
                )}
              </div>
              <input
                id="search"
                name="text"
                placeholder="Busca por zona, localidad o ciudad"
                value={search}
                onChange={handleSearch}
                type="text"
                required
                className="w-full py-[0.95rem] rounded-full font-dmSans text-gray-900 shadow-button bg-white/30 focus:ring-0  focus:border-primary"
              />
            </div>
            <Menu as={"div"}>
              <Menu.Button
                className="p-4 rounded-full bg-primary"
                onClick={handleMenuFilters}
              >
                <img className="h-6" src={Filters} alt="" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items>
                  <div className="absolute right-0 z-40 w-64 h-auto p-2 ml-0 text-black bg-white border md:w-96 border-primary shadow-card rounded-3xl top-3 w">
                    <div>
                      <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                        <div className="min-[480px]:w-full">
                          <div className="min-[480px]:w-full">
                            <Select
                              data={categories}
                              selected={selectedCategory}
                              onChange={handleCategoryChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                        <div className="min-[480px]:w-full">
                          <div className="min-[480px]:w-full">
                            <div>
                              <p className="mt-2 text-black font-dmSans">
                                Ambientes
                              </p>
                              <div className="flex justify-center gap-2 mt-2">
                                {[1, 2, 3, 4, 5, "+6"].map((environments) => (
                                  <button
                                    key={environments}
                                    onClick={() =>
                                      handleEnvironmentsChange(environments)
                                    }
                                    className={`w-12 py-1 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover ${
                                      selectedEnvironments === environments
                                        ? "bg-primaryHover"
                                        : ""
                                    }`}
                                  >
                                    {environments}
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                        <div className="min-[480px]:w-full">
                          <div className="min-[480px]:w-full">
                            <div>
                              <p className="mt-2 text-black font-dmSans">
                                Habitaciones
                              </p>
                              <div className="flex justify-center gap-2 my-2">
                                {[1, 2, 3, 4, 5, "+6"].map((rooms) => (
                                  <button
                                    key={rooms}
                                    onClick={() => handleRoomsChange(rooms)}
                                    className={`w-12 py-1 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover ${
                                      selectedRooms === rooms
                                        ? "bg-primaryHover"
                                        : ""
                                    }`}
                                  >
                                    {rooms}
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <button
                        className={`w-full py-1 text-white rounded-full ${
                          isFilterButtonDisabled()
                            ? "bg-gray-300"
                            : "bg-primaryHover"
                        } font-dmSans ${
                          isFilterButtonDisabled()
                            ? "cursor-not-allowed"
                            : "hover:bg-primary"
                        }`}
                        onClick={handleFilter}
                        disabled={isFilterButtonDisabled()}
                      >
                        Filtrar
                      </button>
                      <button
                        className="w-full py-1 mt-2 text-gray-800 bg-white border border-gray-300 rounded-full font-dmSans hover:bg-primaryHover hover:text-white"
                        onClick={clearFilters}
                      >
                        Limpiar filtros
                      </button>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      {loading ? (
        <CustomSpinner />
      ) : (
        <MagicMotion>
          <div className="min-h-[50vh] mt-5 mb-10">
            {currentProperties.length > 0 ? (
              currentProperties.map((property, i) => (
                <div
                  key={property.id}
                  className="flex items-center justify-between h-20 my-3 border border-gray-200 rounded-full font-dmSans"
                >
                  <div className="flex items-center">
                    <img
                      className="object-cover w-16 h-16 ml-6 rounded-2xl"
                      src={property.images[0]}
                      loading="lazy"
                      alt={`Propiedad ${property.title}`}
                    />
                    <div className="flex ">
                      <div className="flex flex-col ml-2 md:ml-4">
                        <h3 className="text-gray-500 ">Titulo:</h3>
                        <Link to={`/properties/${property.id}`}>
                          <p className="w-[25vw] overflow-hidden whitespace-nowrap min-[480px]:w-[18vw] min-[480px]:max-w-[165px] md:max-w-[110px]">
                            {property.title}
                          </p>
                        </Link>
                      </div>
                      <div className=" flex-col ml-3 hidden min-[480px]:block md:ml-5 xl:ml-7">
                        <h3 className="text-gray-500 ">Operación:</h3>
                        <p className="w-[39.065vw] overflow-hidden whitespace-nowrap min-[480px]:w-[17vw] min-[480px]:max-w-[90px]">
                          {property.operation === "sale" ? "Venta" : "Alquiler"}
                        </p>
                      </div>
                      <div className="flex-col hidden ml-3 sm:block md:ml-5 xl:ml-7">
                        <h3 className="text-gray-500 ">Categoria:</h3>
                        <p className="w-[39.065vw] overflow-hidden whitespace-nowrap min-[480px]:w-[26.044vw] min-[480px]:max-w-[110px]">
                          {property.category}
                        </p>
                      </div>
                      <div className="flex-col hidden ml-3 md:block md:ml-5 xl:ml-7">
                        <h3 className="text-gray-500 ">Precio:</h3>
                        <p className="w-[39.065vw] overflow-hidden whitespace-nowrap min-[480px]:w-[12vw] min-[480px]:max-w-[110px]">
                        {property.currency === "ARG" ? "$" : "USD "}{property.price}
                        </p>
                      </div>
                      <div className="flex-col hidden ml-3 lg:block md:ml-5 xl:ml-7">
                        <h3 className="text-gray-500 ">Pais:</h3>
                        <p className="w-[39.065vw] overflow-hidden whitespace-nowrap min-[480px]:w-[26.044vw] min-[480px]:max-w-[150px]">
                          {`${property.city}, ${property.country}`}
                        </p>
                      </div>
                      <div className="flex-col hidden ml-3 lg:block md:ml-5">
                        <h3 className="text-gray-500 ">Destacada</h3>
                        <input
                          className=""
                          type="checkbox"
                          checked={property.featured}
                          onChange={(e) =>
                            handleCheckboxChange(property.id, e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mr-4 space-x-1">
                    <Link to={`/properties/change/${property.id}`}>
                      <button className="p-2 rounded-full bg-primary hover:bg-primaryHover">
                        <img className="w-5" src={Edit} alt="" />
                      </button>
                    </Link>
                    <button
                      className="p-2 rounded-full bg-primary hover:bg-primaryHover"
                      onClick={() => getPropId(property.id)}
                    >
                      <img className="w-5" src={Delete} alt="" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No se encontraron resultados.</p>
            )}
          </div>
        </MagicMotion>
      )}

      {currentProperties.length > 0 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={(selected) => setCurrentPage(selected.selected + 1)}
          totalPages={totalPages}
        />
      )}

      {action === "deleteProp" ? (
        <DeleteProp
          type="propiedad"
          handleDeleteProp={handleDeleteProp}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  );
};
