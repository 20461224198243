import { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import {
  collection,
  limit,
  onSnapshot,
  query,
  startAfter,
  where,
} from "firebase/firestore";

function useProperties({ queryParameter }) {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [lastElement, setLastElement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [allPropertiesUnlimited, setAllPropertiesUnlimited] = useState([]);
  const propertiesLimit = 6;

  useEffect(() => {
    const collectionRef = collection(db, `properties`);
    const featuredQuery = query(
      collectionRef,
      where("available", "==", true),
      where("featured", "==", true),
      limit(propertiesLimit)
    );
    const salesQuery = query(
      collectionRef,
      where("available", "==", true),
      where("operation", "==", "sale"),
      limit(propertiesLimit)
    );
    const rentQuery = query(
      collectionRef,
      where("available", "==", true),
      where("operation", "==", "rent"),
      limit(propertiesLimit)
    );
    const allQuery = query(
      collectionRef,
      where("available", "==", true),
      limit(propertiesLimit)
    );
    const allQueryUnlimited = query(
      collectionRef,
      where("available", "==", true)
    );

    const q =
      queryParameter === "featured"
        ? featuredQuery
        : queryParameter === "sale"
        ? salesQuery
        : queryParameter === "rent"
        ? rentQuery
        : allQuery;

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        setLastElement(snapshot.docs[snapshot.docs.length - 1]);
        const properties = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProperties(properties);
        setHasMore(snapshot.docs.length === propertiesLimit);
        setIsLoading(false);
      },
      (error) => {
        console.log("error->", error);
        setProperties([]);
        setIsLoading(false);
        setFilteredProperties([]);
      }
    );
    onSnapshot(
      allQueryUnlimited,
      (snapshot) => {
        const allProperties = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAllPropertiesUnlimited(allProperties);
      },
      (error) => {
        console.log("error->", error);
        setAllPropertiesUnlimited([]);
      }
    );

    return () => unsubscribe();
  }, [queryParameter]);

  const nextProperties = () => {
    console.log("Entro");
    const collectionRef = collection(db, `properties`);
    const featuredQuery = query(
      collectionRef,
      where("available", "==", true),
      where("featured", "==", true),
      startAfter(lastElement),
      limit(propertiesLimit)
    );
    const salesQuery = query(
      collectionRef,
      where("available", "==", true),
      where("operation", "==", "sale"),
      startAfter(lastElement),
      limit(propertiesLimit)
    );
    const rentQuery = query(
      collectionRef,
      where("available", "==", true),
      where("operation", "==", "rent"),
      startAfter(lastElement),
      limit(propertiesLimit)
    );
    const allQuery = query(
      collectionRef,
      where("available", "==", true),
      startAfter(lastElement),
      limit(propertiesLimit)
    );

    const q =
      queryParameter === "featured"
        ? featuredQuery
        : queryParameter === "sale"
        ? salesQuery
        : queryParameter === "rent"
        ? rentQuery
        : allQuery;

    onSnapshot(
      q,
      (snapshot) => {
        setLastElement(snapshot.docs[snapshot.docs.length - 1]);
        const nextProperties = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProperties((properties) => [...properties, ...nextProperties]);
        setHasMore(snapshot.docs.length === propertiesLimit);
        setIsLoading(false);
      },
      (error) => {
        console.log("error->", error);
        setIsLoading(false);
        setFilteredProperties([]);
      }
    );
  };

  return {
    properties,
    setProperties,
    isLoading,
    filteredProperties,
    nextProperties,
    hasMore,
    allPropertiesUnlimited,
  };
}

export default useProperties;
