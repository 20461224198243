import React from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const Select = ({ onChange, selected, data }) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="text-black font-dmSans">
            Categoría
          </Listbox.Label>
          <div className="relative mb-2">
            <Listbox.Button className="w-full text-center p-[0.5rem] border border-gray-300 rounded-full font-dmSans  text-gray-600 shadow-button focus:ring-0 focus:border-primary">
              <span className="block truncate">
                {selected || "Todas las categorías"}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-gray-400" />
              </span>
            </Listbox.Button>

            <Listbox.Options className="absolute z-10 w-full mt-1 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {data.map((data) => (
                <Listbox.Option
                  key={data.id}
                  value={data.name}
                  className={({ active }) =>
                    `${
                      active ? "text-white bg-primary" : "text-gray-900"
                    } cursor-pointer select-none relative py-2 pl-3 pr-9`
                  }
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {data.name}
                      </span>
                      {selected && (
                        <span
                          className={`${
                            active ? "text-white" : "text-primary"
                          } absolute inset-y-0 right-0 flex items-center pr-4`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
