import { useEffect, useState } from "react";
import {
  collection,
  doc,
  updateDoc,
  orderBy,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

function usePropertiesPanel() {
  const [properties, setProperties] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const getPropertiesCollectionSnapshot = async () => {
    const propertiesCollection = collection(db, "properties");
    return await getDocs(propertiesCollection);
  };

  const getAllProperties = async () => {
    const snapshot = await getPropertiesCollectionSnapshot();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const fetchProperties = async () => {
    try {
      setLoading(true);

      let baseQuery = query(
        collection(db, "properties"),
        orderBy("featured", "desc")
      );
      if (isActive) {
        baseQuery = query(baseQuery, where("available", "==", true));
      }

      const unsubscribe = onSnapshot(baseQuery, (snapshot) => {
        const updatedProperties = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProperties(updatedProperties);
      });
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching properties: ", error);
    } finally {
      setLoading(false);
    }
  };

  const addProperty = async (propertyValues) => {
    try {
      setLoading(true);
      const docRef = await addDoc(collection(db, "properties"), propertyValues);
      console.log("Property added with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding property: ", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteProperty = async (propertyId) => {
    try {
      const propertyDocRef = doc(db, "properties", propertyId);
      await deleteDoc(propertyDocRef);
      console.log("Property deleted successfully!");
    } catch (error) {
      console.error("Error deleting property: ", error);
      throw error;
    }
  };

  const getPropertyById = async (propertyId) => {
    try {
      setLoading(true);
      const propertyDocRef = doc(db, "properties", propertyId);
      const propertyDoc = await getDoc(propertyDocRef);
      if (propertyDoc.exists()) {
        return { id: propertyDoc.id, ...propertyDoc.data() };
      } else {
        throw new Error("Property not found");
      }
    } catch (error) {
      console.error("Error getting property by ID: ", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateProperty = async (propertyId, updatedValues) => {
    try {
      setLoading(true);
      const propertyDocRef = doc(db, "properties", propertyId);
      await updateDoc(propertyDocRef, updatedValues);
      console.log("Property updated successfully!");
    } catch (error) {
      console.error("Error updating property: ", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const uploadImagesToFirebase = async (images) => {
    setLoading(true);
    const storage = getStorage();
    const imageUrls = [];
    const storageRef = ref(storage, "images");
    for (const image of images) {
      const imageRef = ref(storageRef, image.name);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);
      imageUrls.push(imageUrl);
    }
    setLoading(false);
    return imageUrls;
  };

  const updateFeaturedStatus = async (propertyId, isFeatured) => {
    try {
      const propertyDocRef = doc(db, "properties", propertyId);
      await updateDoc(propertyDocRef, {
        featured: isFeatured,
      });
    } catch (error) {
      console.error("Error updating featured status: ", error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [isActive]);

  return {
    properties,
    updateFeaturedStatus,
    setIsActive,
    isActive,
    getAllProperties,
    addProperty,
    deleteProperty,
    uploadImagesToFirebase,
    getPropertyById,
    updateProperty,
    loading,
  };
}

export default usePropertiesPanel;
