import { Link } from "react-router-dom";
import CustomSpinner from "../commons/customSpinner";
import useConsultas from "../hooks/useConsultas";
import { useState } from "react";
import useAgentes from "../hooks/useAgentes";
import SelectAgent from "../commons/SelectAgent";
import flechaIcon from "../utils/icons/arrow.svg";
import SwitchButton from "../commons/SwitchButton";
import Pagination from "../commons/Pagination";

const ConsultasPanel = () => {
  const [isActive, setIsActive] = useState(false);
  const [sortDate, setSortDate] = useState(true);
  const { consultas, isLoading, calculateSemaphorColor, toggleEstadoConsulta } =
    useConsultas(isActive, sortDate);
  const { agentes } = useAgentes();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const currentConsultas = consultas.slice(startIndex, endIndex);
  const totalPages = Math.ceil(consultas.length / itemsPerPage);

  if (consultas.length === 0 && agentes.length === 0) {
    return <CustomSpinner />;
  }
  if (!consultas) {
    return <span>No hay consultas todavía</span>;
  }

  const handleSetSortDate = () => {
    setSortDate(!sortDate);
  };
  return (
    <div>
      <div
        className={`flex ${
          totalPages > 0 ? "justify-between" : "justify-end"
        }  py-7`}
      >
        {totalPages > 0 && (
          <span>
            Paginas ({currentPage}/{totalPages})
          </span>
        )}
        <div className={totalPages > 0 ? "" : "w-full"}>
          <SwitchButton
            type="consultas"
            labelActive="Resueltas"
            labelInactive="Pendientes"
            isActive={isActive}
            setIsActive={setIsActive}
          />
        </div>
      </div>
      {isLoading ? (
        <CustomSpinner />
      ) : consultas.length === 0 ? (
        <div className="w-full py-10 lg:py-20">
          <p className="text-center">
            No hay consultas {isActive ? "resueltas" : "pendientes"}
          </p>
        </div>
      ) : (
        <div>
          <ul className="text-sm divide-y divide-gray-100 md:text-base">
            <li className="flex items-end justify-between font-medium gap-x-6">
              <div className="flex items-end min-w-0 sm:flex-1 flex-2 gap-x-4">
                <div className="flex-auto min-w-0 font-medium">
                  <p className="text-sm leading-6 text-start">
                    Datos del Cliente
                  </p>
                </div>
              </div>
              <span className="flex-1 hidden text-center md:block">
                Propiedad
              </span>
              <span className="flex-1 hidden text-center truncate md:block">
                Agente Encargado
              </span>
              <div
                onClick={handleSetSortDate}
                className="justify-end flex-1 hidden gap-1 cursor-pointer shrink-0 sm:flex-row sm:flex sm:items-end"
              >
                <p className="text-sm leading-6 truncate text-end">
                  Fecha de consulta
                </p>
                <img
                  className={`inline-block w-4 h-4 ml-1 ${
                    sortDate ? "transform rotate-0" : "transform rotate-180"
                  }`}
                  src={flechaIcon}
                  alt="icono flecha"
                />
              </div>
              <div className="flex-1 text-end">Estado</div>
            </li>
          </ul>
          <ul className="divide-y divide-gray-100">
            {currentConsultas.map((person) => {
              const fecha = new Date(person.timestamp);
              const fechaFormateada = fecha.toLocaleDateString();
              const horario = fecha.toLocaleTimeString();
              return (
                <li
                  key={person.email}
                  className="flex items-center justify-between py-5 gap-x-6"
                >
                  <div className="flex items-center min-w-0 sm:flex-1 flex-2 gap-x-4">
                    {!isActive && (
                      <div
                        className={`flex-none w-12 h-12 bg-${calculateSemaphorColor(
                          person?.timestamp
                        )}-500 rounded-full`}
                      />
                    )}
                    <div className="flex-auto min-w-0">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {person.clientName}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500 truncate">
                        {person.email}
                      </p>
                      <p className="text-xs leading-5 text-gray-500 truncate">
                        {person.phone}
                      </p>
                    </div>
                  </div>
                  <Link
                    className="flex-1 hidden text-center text-blue-500 md:block hover:underline hover:text-blue-700"
                    to={`/properties/${person.propertyId}`}
                    target="blank"
                  >
                    Ver
                  </Link>
                  <div className="flex-1 hidden md:block">
                    <SelectAgent
                      consultaId={person.id}
                      consultas={person}
                      agentes={agentes}
                    />
                  </div>
                  <div className="flex-1 hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">
                      {fechaFormateada}
                    </p>
                    <p className="text-sm leading-6 text-gray-700">{horario}</p>
                  </div>
                  <div className="flex-1 text-end">
                    <input
                      type="checkbox"
                      className={`text-primary focus:ring-primary`}
                      onChange={() =>
                        toggleEstadoConsulta(person.id, person.resolved)
                      }
                      checked={person.resolved}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <Pagination
            currentPage={currentPage}
            onPageChange={(selected) => setCurrentPage(selected.selected + 1)}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
};
export default ConsultasPanel;
