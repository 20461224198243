import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DW from "../utils/images/LogoVoid.png";
import { useAuth } from "../AuthContext";
import { Menu, Transition } from "@headlessui/react";
import MenuBurger from "../utils/icons/MenuBurger.svg";
function Navbar() {
  const navigate = useNavigate();
  const { currentUser, logout, isLoading } = useAuth();
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <Menu
      as={"nav"}
      className="absolute flex w-full mt-3 px-[3vw] py-[1.4vw] justify-center z-20 sm:px-[3vw] sm:py-[4.4vw] md:px-[2.4vw] md:py-[1vw] lg:px-[3.5vw] xl:px-[10vw]"
    >
      <div className="container relative flex flex-wrap items-center justify-between w-11/12 px-6 py-3 mx-auto bg-white rounded-full shadow-nav md:w-full md:pl-4 md:pr-0 md:mx-0">
        <Link to="/">
          <img src={DW} alt="DWilliams" style={{ maxWidth: "60px" }} />
        </Link>
        <Menu.Button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center py-2 ml-3 text-sm text-white md:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <img
            className="w-10 h-10"
            src={MenuBurger}
            alt="icono hamburguesa D' Williams"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg pt-14 top-8 -z-10 ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/"
                    className={`block py-2 pl-3 pr-4 ${
                      active && "bg-primary text-white"
                    }  md:bg-transparent md:p-0 `}
                  >
                    Home
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/properties"
                    className={`block py-2 pl-3 pr-4 ${
                      active && "bg-primary text-white"
                    }  md:bg-transparent md:p-0 `}
                  >
                    Propiedades
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/sale"
                    className={`block py-2 pl-3 pr-4 ${
                      active && "bg-primary text-white"
                    }  md:bg-transparent md:p-0 `}
                  >
                    En Venta
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/rent"
                    className={`block py-2 pl-3 pr-4 ${
                      active && "bg-primary text-white"
                    }  md:bg-transparent md:p-0 `}
                  >
                    Alquileres
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) =>
                  currentUser && !isLoading ? (
                    <Link
                      to="/panelAdmin"
                      className={`block py-2 pl-3 pr-4 ${
                        active && "bg-primary text-white"
                      }  md:bg-transparent md:p-0 `}
                    >
                      Admin
                    </Link>
                  ) : null
                }
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className=" absolute z-40 flex flex-col bg-transparent p-[5vw] mt-3 w-full -left-[0.02rem] text-base text-gray-900 font-dmSans md:p-0 md:px-0 md:w-full md:static md:flex-row md:space-x-8 md:space-y-0 md:mt-0 md:text-sm md:font-normal md:border-0 md:bg-transparent md:text-gray-900 lg:text-lg">
            <div className="bg-white shadow-dropdown rounded-3xl py-3 px-2 mx-[2vw] sm:py-5 md:shadow-none md:items-center md:py-0 md:px-0 md:flex md:flex-row md:rounded-none md:space-x-8 md:mx-4">
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 hover:text-primary md:bg-transparent md:p-0 "
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/properties"
                  className="block py-2 pl-3 pr-4 hover:text-primary md:bg-transparent md:p-0 "
                >
                  Propiedades
                </Link>
              </li>
              <li>
                <Link
                  to="/sale"
                  className="block py-2 pl-3 pr-4 hover:text-primary md:bg-transparent md:p-0"
                >
                  Venta
                </Link>
              </li>
              <li>
                <Link
                  to="/rent"
                  className="block py-2 pl-3 pr-4 hover:text-primary md:bg-transparent md:p-0"
                >
                  Alquileres
                </Link>
              </li>
              {currentUser && !isLoading ? (
                <li>
                  <Link
                    to="/panelAdmin"
                    className="block py-2 pl-3 pr-4 hover:text-primary md:bg-transparent md:p-0"
                  >
                    Admin
                  </Link>
                </li>
              ) : null}
              <li className="flex justify-center my-2 sm:my-4 md:my-0">
                {currentUser ? (
                  <button
                    className="block w-11/12 py-2 pl-3 pr-4 font-semibold text-white rounded-full bg-primary hover:bg-primaryHover md:py-4 md:px-6 md:bg-primary md:w-full md:p-0"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                ) : (
                  <Link
                    className="block w-11/12 py-2 pl-3 pr-4 font-semibold text-center text-white rounded-full bg-primary hover:bg-primaryHover md:py-4 md:px-6 md:bg-primary md:w-full md:p-0"
                    to="/login"
                  >
                    <button>Log in</button>
                  </Link>
                )}
              </li>
            </div>
          </ul>
        </div>
      </div>
    </Menu>
  );
}

export default Navbar;
