import React from "react";
import WallpaperImage from "../utils/images/wallpaperRent.jpg";
import PropertyCard from "../commons/PropertyCard";
import useProperties from "../hooks/useProperties";
import CustomSpinner from "../commons/customSpinner";
import InfiniteScroll from "react-infinite-scroll-component";

function Rent() {
  const { properties, isLoading, nextProperties, hasMore } = useProperties({
    queryParameter: "rent",
  });

  return (
    <>
      <section>
        <div>
          <div className="relative h-[73vh] md:h-[82vh] lg:h-[76vh]">
            <img
              className="absolute object-cover w-full h-full rounded-b-3xl"
              src={WallpaperImage}
              alt=""
            />
            <div className="absolute flex items-center w-full h-full text-center bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/90 rounded-b-3xl">
              <div className="mx-[5vw] w-full text-white font-dmSans my-10 xl:px-[5vw]">
                <h3 className="text-[3rem] font-semibold mb-4 leading-10 min-[480px]:text-[2.375rem] md:text-5xl lg:text-6xl">
                  Alquileres
                </h3>
              </div>
            </div>
          </div>
          {isLoading ? (
            <CustomSpinner />
          ) : (
            <InfiniteScroll
              dataLength={properties.length}
              next={nextProperties}
              hasMore={hasMore}
              loader={<CustomSpinner />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Las has visto todas!</b>
                </p>
              }
            >
              <div className=" my-10 mx-[5vw] md:my-24 xl:px-[5vw]">
                <div className="grid gap-10 grid-cols md:grid-cols-2 lg:grid-cols-3 lg:gap-x-7">
                  {properties && properties.length > 0 ? (
                    properties.map((property, i) => (
                      <PropertyCard key={property.id} property={property} />
                    ))
                  ) : (
                    <div className="flex items-center justify-center col-span-2 md:col-span-1 lg:col-span-3">
                      <p className="text-center text-gray-500">
                        No hay propiedades en alquiler aún.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </InfiniteScroll>
          )}
        </div>
      </section>
    </>
  );
}
export default Rent;
