import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Property from "./components/Property";
import Properties from "./components/Properties";
import Sale from "./components/Sale";
import Rent from "./components/Rent";
import Login from "./components/Login";
import NewProperty from "./components/NewProperty";
import UpdateProperty from "./components/UpdateProperty";
import PanelAdmin from "./components/PanelAdmin";
import { AuthProvider, useAuth } from "./AuthContext";
import { PropertiesPanel } from "./components/PropertiesPanel";
import CategoriesPanel from "./components/CategoriesPanel";
import UsuariosPanel from "./components/UsuariosPanel";
import ConsultasPanel from "./components/ConsultasPanel";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sale" element={<Sale />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/login" element={<Login />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/properties/:id" element={<Property />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/properties/create" element={<NewProperty />} />
          <Route path="/properties/change/:id" element={<UpdateProperty />} />
          <Route path="/panelAdmin" element={<PanelAdmin />}>
            <Route index element={<PropertiesPanel />} />
            <Route path="propiedades" end element={<PropertiesPanel />} />
            <Route path="consultas" element={<ConsultasPanel />} />
            <Route path="usuarios" element={<UsuariosPanel />} />
            <Route path="categorias" element={<CategoriesPanel />} />
          </Route>
        </Route>
      </Routes>
      <Footer />
    </AuthProvider>
  );
};

export default App;
