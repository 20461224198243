import React, { useState } from "react";
import usePropertiesPanel from "../hooks/usePropertiesPanel";
import Swal from "sweetalert2";
import { Spinner } from "./Spinner";

const AddImagesModal = ({
  handleCloseModal,
  setPropertyValues,
  propertyValues,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [fileMessage, setFileMessage] = useState("Selecciona imáganes");
  const { uploadImagesToFirebase, loading } = usePropertiesPanel();

  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedImages([...files]);
    setSelectedImageIndex(null);
    setFileMessage(
      files.length === 0
        ? "Selecciona imágenes"
        : files.length === 1
        ? "1 imagen seleccionada"
        : `${files.length} imágenes seleccionadas`
    );
  };

  const handleRemoveImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
    if (index === selectedImageIndex) {
      setSelectedImageIndex(null);
    }
    setFileMessage(
      newSelectedImages.length === 0
        ? "Selecciona imágenes"
        : newSelectedImages.length === 1
        ? "1 imagen seleccionada"
        : `${newSelectedImages.length} imágenes seleccionadas`
    );
  };

  const handleSelectImage = (index) => {
    setSelectedImageIndex(index);
  };

  const handleSaveImages = async () => {
    if (selectedImages.length === 0) {
      await Swal.fire({
        icon: "warning",
        text: "Debes agregar al menos una imagen.",
      });
      return;
    }
    if (selectedImageIndex === null) {
      await Swal.fire({
        icon: "warning",
        text: "Debes seleccionar al menos una imagen como portada.",
      });
      return;
    }
    try {
      const imageUrls = await uploadImagesToFirebase(selectedImages);
      setPropertyValues((prevValues) => ({
        ...prevValues,
        images: [
          imageUrls[selectedImageIndex],
          ...imageUrls.slice(0, selectedImageIndex),
          ...imageUrls.slice(selectedImageIndex + 1),
        ],
      }));
      await Swal.fire({
        icon: "success",
        text: "¡Imágenes guardadas!",
      });
    } catch (error) {
      await Swal.fire({
        icon: "error",
        text: "Error al guardar las imágenes",
      });
    }
    handleCloseModal();
  };

  return (
    <div
      className={
        "fixed inset-0 z-50 flex items-center justify-center w-full min-h-screen animated fadeIn faster overflow-auto "
      }
    >
      <div className={"absolute inset-0 bg-black opacity-70"} />
      <div className="relative flex flex-col w-11/12 gap-5 p-6 m-auto overflow-x-auto bg-white shadow-lg lg:w-3/4 rounded-3xl">
        <div className="relative w-full">
          <input
            className={
              "absolute inset-0  h-full opacity-0 cursor-pointer rounded-3xl border border-primary "
            }
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            multiple
          />
          <button
            type="button"
            className="w-full px-4 py-2 font-bold text-white rounded bg-primary "
          >
            {fileMessage}
          </button>
        </div>
        <div className="flex items-center justify-start gap-4 overflow-x-auto">
          {selectedImages.length === 0 ? (
            <span className="text-sm text-center ">
              Ninguna imagen seleccionada
            </span>
          ) : (
            selectedImages.map((image, index) => (
              <div
                key={index}
                className={`relative cursor-pointer min-h-[180px] min-w-[180px] h-44 w-44 border-2 ${
                  index === selectedImageIndex ? "border-primary" : ""
                }`}
                onClick={() => handleSelectImage(index)}
              >
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Imagen seleccionada ${index}`}
                  className="object-cover w-full h-full thumbnail-style"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-white bg-red-500"
                  onClick={() => handleRemoveImage(index)}
                >
                  X
                </button>
              </div>
            ))
          )}
        </div>
        <div className="flex justify-center gap-4">
          <button
            onClick={handleSaveImages}
            type="button"
            className={
              "w-auto px-6 py-3 text-base font-dmSans text-white text-center rounded-full bg-primary hover:bg-primaryHover focus:ring-0"
            }
          >
            {loading ? <Spinner /> : "Guardar"}
          </button>
          <button
            type="button"
            onClick={handleCloseModal}
            className={
              "w-auto px-6 py-3 text-base font-dmSans text-center text-black bg-white border border-gray-200 rounded-full hover:bg-primary hover:text-white focus:ring-0"
            }
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddImagesModal;
