// useLocationData.js
import { useState, useEffect } from "react";

const useLocationData = () => {
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("San Luis");
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    fetchProvinces();
    if (selectedProvince) {
      fetchMunicipalities(selectedProvince);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedProvince && selectedMunicipality) {
      fetchLocalities(selectedProvince, selectedMunicipality);
    }
  }, [selectedProvince, selectedMunicipality]);

  const API_URL = "https://apis.datos.gob.ar/georef/api";
  const fetchProvinces = async () => {
    try {
      const response = await fetch(`${API_URL}/provincias`);
      const data = await response.json();
      setProvinces(data.provincias || []);
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const fetchMunicipalities = async (province) => {
    try {
      const response = await fetch(
        `${API_URL}/municipios?provincia=${province}&max=100`
      );
      const data = await response.json();
      setMunicipalities(data.municipios || []);
    } catch (error) {
      console.error("Error fetching municipalities:", error);
    }
  };

  const fetchLocalities = async (province, municipality) => {
    try {
      const response = await fetch(
        `${API_URL}/localidades?provincia=${province}&municipio=${municipality}&max=100`
      );
      const data = await response.json();
      setLocalities(data.localidades || []);
    } catch (error) {
      console.error("Error fetching localities:", error);
    }
  };

  return {
    provinces,
    municipalities,
    localities,
    selectedProvince,
    selectedMunicipality,
    setSelectedProvince,
    setSelectedMunicipality,
    setSelectedLocation,
    selectedLocation,
    fetchProvinces,
    fetchMunicipalities,
    fetchLocalities,
  };
};

export default useLocationData;
