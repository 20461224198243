import { useState } from "react";
import useCategories from "../hooks/useCategories";
import Delete from "../utils/icons/Delete.svg";
import Edit from "../utils/icons/Edit.svg";
import Add from "../utils/icons/Add.svg";
import DeleteProp from "../commons/DeleteProp";
import CustomSpinner from "../commons/customSpinner";
import AddNewModal from "./AddNewModal";
import UpdateModal from "./UpdateModal";

const CategoriesPanel = () => {
  const { categories, addCategory, updateCategory, deleteCategory } =
    useCategories();
  const [action, setAction] = useState("");
  const [propId, setPropId] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const getPropId = (id) => {
    setPropId(id);
    return setAction("deleteProp");
  };
  const handleOpenEditCategoryModal = (id, name) => {
    setCategoryName(name);
    setCategoryId(id);
    setAction("editCatgory");
  };
  const handleOpenAddCategoryModal = () => {
    setAction("addCategory");
  };

  const handleCloseModal = () => {
    return setAction("");
  };
  console.log(categories);
  return (
    <div>
      <div className="flex justify-end w-full mt-4">
        <button
          onClick={handleOpenAddCategoryModal}
          className="flex items-center px-4 py-2 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover"
        >
          Nueva
          <img className="h-5 ml-2" src={Add} alt="" />
        </button>
      </div>
      <div className="min-h-[50vh] mt-5 mb-14">
        {categories && categories.length > 0 ? (
          categories.map((element, i) => (
            <div
              key={element.id}
              className="flex items-center justify-between h-20 px-6 my-3 border border-gray-200 rounded-full font-dmSans"
            >
              <div className="flex items-center">
                <div className="flex xl:grid xl:grid-cols-5 xl:gap-x-3">
                  <div className="flex flex-col ml-2 md:ml-4">
                    <h3 className="text-gray-500 ">Titulo:</h3>
                    <p className="w-[25vw] overflow-hidden whitespace-nowrap min-[480px]:w-[18vw] min-[480px]:max-w-[165px] md:max-w-[180px]">
                      {element.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex space-x-1">
                <button
                  onClick={() =>
                    handleOpenEditCategoryModal(element.id, element.name)
                  }
                  className="p-2 rounded-full bg-primary hover:bg-primaryHover"
                >
                  <img className="w-5" src={Edit} alt="" />
                </button>
                <button
                  className="p-2 rounded-full bg-primary hover:bg-primaryHover"
                  onClick={() => getPropId(element.id)}
                >
                  <img className="w-5" src={Delete} alt="" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <CustomSpinner />
        )}
      </div>
      {action === "addCategory" && (
        <AddNewModal
          type="categoria"
          title="Nueva Categoría"
          data={categories}
          addFunction={addCategory}
          handleCloseModal={handleCloseModal}
          categoryLabel="Casa"
        />
      )}
      {action === "editCatgory" && (
        <UpdateModal
          data={categories}
          updateFunction={updateCategory}
          itemName={categoryName}
          itemId={categoryId}
          type="Categoría"
          handleCloseModal={handleCloseModal}
        />
      )}
      {action === "deleteProp" && (
        <DeleteProp
          type={"categoria"}
          handleDeleteProp={deleteCategory}
          closeModal={handleCloseModal}
          propId={propId}
        />
      )}
    </div>
  );
};

export default CategoriesPanel;
