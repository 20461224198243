import React, { useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "../commons/Spinner";

const AddNewModal = ({
  data,
  title,
  type,
  handleCloseModal,
  categoryLabel,
  addFunction,
  additionalInputLabel,
}) => {
  const [newName, setNewName] = useState(
    categoryLabel === "Casa" ? "Casa" : ""
  );
  const [additionalInput, setAdditionalInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const displaySwal = (icon, text) => {
    Swal.fire({
      icon,
      text,
    });
  };

  const handleChangeName = (event) => {
    setNewName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setAdditionalInput(event.target.value);
  };

  const handleAdd = async () => {
    const trimText = newName.trim();

    if (!trimText) {
      displaySwal("warning", "No puedes agregar un campo vacío.");
      return;
    }

    const existsMessage = additionalInput
      ? "Ese agente ya existe."
      : "Esa categoría ya existe.";

    if (categoryExists(trimText) || agentExists(trimText)) {
      displaySwal("warning", existsMessage);
      return;
    }

    setIsLoading(true);

    try {
      await addFunction(newName, additionalInput);
      const successMessage = additionalInput
        ? "Agente agregado exitosamente!"
        : "Categoría agregada exitosamente!";
      displaySwal("success", successMessage);
      handleCloseModal();
    } catch (error) {
      const errorMessage = additionalInput
        ? "Se produjo un error al agregar el agente."
        : "Se produjo un error al agregar la categoría.";
      displaySwal("error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const categoryExists = (text) =>
    data.some(
      (item) => item.name && item.name.toLowerCase() === text.toLowerCase()
    );

  const agentExists = (text) =>
    data.some(
      (item) =>
        item.agentName && item.agentName.toLowerCase() === text.toLowerCase()
    );

  const modalStyles = {
    container:
      "fixed inset-0 z-50 flex items-center justify-center min-h-screen animated fadeIn faster",
    background: "absolute inset-0 bg-black opacity-70",
    content:
      "w-full max-w-sm relative mx-2 my-auto p-6 shadow-lg bg-white rounded-3xl flex flex-col gap-5",
    heading: "font-semibold font-dmSans",
    input: "text",
    buttonPrimary:
      "w-auto px-6 py-3 text-base font-dmSans text-white text-center rounded-full bg-primary hover:bg-primaryHover focus:ring-0",
    buttonSecondary:
      "w-auto px-6 py-3 text-base font-dmSans text-center text-black bg-white border border-gray-200 rounded-full hover:bg-primary hover:text-white focus:ring-0",
  };

  return (
    <div className={modalStyles.container}>
      <div className={modalStyles.background} />
      <div className={modalStyles.content}>
        <h2 className={modalStyles.heading}>Agregar {title}</h2>
        <input
          type="text"
          value={newName}
          onChange={handleChangeName}
          placeholder={`Nombre de ${type}`}
          className={modalStyles.input}
        />
        {additionalInputLabel && (
          <input
            type="text"
            value={additionalInput}
            onChange={handleChangeEmail}
            placeholder={additionalInputLabel}
            className={modalStyles.input}
          />
        )}
        <div className={modalStyles.buttonContainer}>
          <button className={modalStyles.buttonPrimary} onClick={handleAdd}>
            {isLoading ? <Spinner /> : "Agregar"}
          </button>
          <button
            className={modalStyles.buttonSecondary}
            onClick={handleCloseModal}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewModal;
