import Gym from "./icons/Gym.svg";
import Spool from "./icons/SPool.svg";
import Jacuzzi from "./icons/Jacuzzi.svg";
import GreenArea from "./icons/GreenArea.svg";
import Bar from "./icons/Bar.svg";
import Cal from "./icons/Calefaccionada.svg";
import Terraza from "./icons/Terraza.svg";
import Alarma from "./icons/Terraza.svg";
import Segura from "./icons/Segura.svg";
import Comedor from "./icons/Comedor.svg";
import Camaras from "./icons/Camaras.svg";
import Garage from "./icons/Garage.svg";
import Wifi from "./icons/Wifi.svg";

const getAreaIcon = {
  Gym: Gym,
  Pileta: Spool,
  Jacuzzi: Jacuzzi,
  Jardin: GreenArea,
  Bar: Bar,
  Calefaccionada: Cal,
  Terraza: Terraza,
  Alarma: Alarma.svg,
  Segura: Segura,
  Comedor: Comedor,
  Camaras: Camaras,
  Garage: Garage,
  Wifi: Wifi,
};

export default getAreaIcon;
