import { createContext, useContext, useEffect, useState } from "react";

import Swal from "sweetalert2";
import { auth } from "./firebase/firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const loginAuth = async (email, password) => {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      Swal.fire({
        title: "Bienvenido!",
        text: "Pongasé a laburar mijito de mierda.",
        icon: "success",
      });
      navigate("/panelAdmin");
    } catch (error) {
      let errorCode = error.code;
      let errorMessage = error.message;
      const messages = {
        "auth/invalid-email": "El correo es inválido.",
        "auth/user-disabled":
          "El usuario ha sido inhabilitado. Consulta al personal Tcnico.",
        "auth/user-not-found":
          "¡Momento! ¿Quién eres? No hemos encontrado al usuario que ingresaste.",
        "auth/wrong-password":
          "¡Oops! La contraseña que pusiste es incorrecta. Intenta de nuevo con otra contraseña",
      };
      Swal.fire({
        title: "Error",
        text: messages[errorCode],
        icon: "error",
        confirmButtonColor: "#641315",
      });
      console.log("Error in login:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    await auth.signOut();
    localStorage.clear();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      user ? setCurrentUser(user) : setCurrentUser(null);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loginAuth,
    logout,
    isLoading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
