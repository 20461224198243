import { useState } from "react";
import Delete from "../utils/icons/Delete.svg";
import Edit from "../utils/icons/Edit.svg";
import Add from "../utils/icons/Add.svg";
import DeleteProp from "../commons/DeleteProp";
import CustomSpinner from "../commons/customSpinner";
import useAgentes from "../hooks/useAgentes";
import AddNewModal from "./AddNewModal";
import UpdateModal from "./UpdateModal";

const UsuariosPanel = () => {
  const { agentes, addAgente, updateAgente, deleteAgent } = useAgentes();
  const [action, setAction] = useState("");
  const [propId, setPropId] = useState("");
  const [agentName, setAgentName] = useState(null);
  const [agentEmail, setAgentEmail] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const getPropId = (id) => {
    setPropId(id);
    return setAction("deleteProp");
  };
  const handleOpenEditCategoryModal = (id, name, email) => {
    setAgentName(name);
    setCategoryId(id);
    setAgentEmail(email);
    setAction("editCatgory");
  };
  const handleOpenAddCategoryModal = () => {
    setAction("addCategory");
  };

  const handleCloseModal = () => {
    return setAction("");
  };
  return (
    <div>
      <div className="flex justify-end w-full mt-4">
        <button
          onClick={handleOpenAddCategoryModal}
          className="flex items-center px-4 py-2 text-white rounded-full bg-primary font-dmSans hover:bg-primaryHover"
        >
          Nueva
          <img className="h-5 ml-2" src={Add} alt="" />
        </button>
      </div>
      <div className="min-h-[50vh] mt-5 mb-14">
        {agentes && agentes.length > 0 ? (
          agentes.map((element, i) => (
            <div
              key={element.id}
              className="flex items-center justify-between h-20 px-6 my-3 border border-gray-200 rounded-full font-dmSans"
            >
              <div className="flex-1">
                <div className="flex xl:grid xl:grid-cols-6 xl:gap-x-3">
                  <div className="flex flex-col ml-2 md:ml-4">
                    <h3 className="text-gray-500 ">Titulo:</h3>
                    <p className="w-[25vw] overflow-hidden whitespace-nowrap min-[480px]:w-[18vw] min-[480px]:max-w-[165px] md:max-w-[110px]">
                      {element.agentName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-1 hidden sm:block ">
                <div className="flex justify-center xl:grid xl:grid-cols-6 xl:gap-x-3">
                  <div className="flex flex-col w-full ml-2 md:ml-4">
                    <h3 className="text-gray-500 ">Email:</h3>
                    <p className="w-[25vw] overflow-hidden whitespace-nowrap min-[480px]:w-[18vw] min-[480px]:max-w-[165px] md:max-w-[180px]">
                      {element.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-end flex-1 gap-1 text-end">
                <button
                  onClick={() =>
                    handleOpenEditCategoryModal(
                      element.id,
                      element.agentName,
                      element.email
                    )
                  }
                  className="p-2 rounded-full bg-primary hover:bg-primaryHover"
                >
                  <img className="w-5" src={Edit} alt="" />
                </button>
                <button
                  className="p-2 rounded-full bg-primary hover:bg-primaryHover"
                  onClick={() => getPropId(element.id)}
                >
                  <img className="w-5" src={Delete} alt="" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <CustomSpinner />
        )}
      </div>
      {action === "addCategory" && (
        <AddNewModal
          type="agente"
          title="Nuevo Agente"
          data={agentes}
          addFunction={addAgente}
          handleCloseModal={handleCloseModal}
          categoryLabel="Agente"
          additionalInputLabel="ejemplo@gmail.com"
        />
      )}
      {action === "editCatgory" && (
        <UpdateModal
          data={agentes}
          updateFunction={updateAgente}
          itemName={agentName}
          itemId={categoryId}
          type="Agente"
          handleCloseModal={handleCloseModal}
          additionalInputLabel={agentEmail}
        />
      )}
      {action === "deleteProp" && (
        <DeleteProp
          type={"agente"}
          handleDeleteProp={deleteAgent}
          closeModal={handleCloseModal}
          propId={propId}
        />
      )}
    </div>
  );
};

export default UsuariosPanel;
