function SwitchButton({
  type,
  setIsActive,
  isActive,
  labelActive,
  labelInactive,
}) {
  const toggleButton = () => {
    setIsActive(!isActive);
  };

  return (
    <label className="flex items-center justify-end cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="hidden"
          checked={isActive}
          onChange={toggleButton}
        />
        <div
          className={`toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner ${
            isActive ? "bg-green-500" : "bg-red-500"
          }`}
        />
        <div
          className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 ${
            isActive ? "translate-x-full bg-green-500" : "bg-red-500"
          }`}
        />
      </div>
      <div
        className={`ml-3 text-lg font-semibold ${
          isActive ? "text-green-500" : "text-red-500"
        }`}
      >
        {isActive ? labelActive : labelInactive}
      </div>
    </label>
  );
}

export default SwitchButton;
