import { useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "./Spinner";

const DeleteProp = ({ type, handleDeleteProp, closeModal, propId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const displaySwal = (icon, title) => {
    Swal.fire({
      icon,
      title,
    });
  };

  const handleCloseModal = async () => {
    setIsLoading(true);
    try {
      await handleDeleteProp(propId);
      closeModal();
    } catch (error) {
      displaySwal("error", `Error al eliminar ${type}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center h-screen bg-center bg-no-repeat bg-cover outline-none min-w-screen animated fadeIn faster focus:outline-none"
      id="modal-id"
    >
      <div className="absolute inset-0 z-0 bg-black opacity-70" />
      <div className="relative w-full h-auto max-w-sm p-6 mx-2 my-auto bg-white shadow-lg rounded-3xl">
        <div className="">
          <div className="text-center p-5 justify-center min-[480px]:flex min-[480px]:items-center min-[480px]:space-x-4 min-[480px]:my-2">
            <img
              className="h-20 mx-auto mb-4 min-[480px]:mb-0"
              src="https://www.svgrepo.com/show/490979/alert.svg"
              alt=""
            />
            <h2 className="font-semibold font-dmSans">
              ¿Estas seguro que deseas borrar esta {type}?
            </h2>
          </div>
          <div className="flex justify-center space-x-2">
            <button
              className="w-auto px-6 py-3 text-base text-center text-white rounded-full font-dmSans bg-primary hover:bg-primaryHover focus:ring-0"
              onClick={handleCloseModal}
            >
              {isLoading ? <Spinner /> : "Borrar"}
            </button>
            <button
              className="w-auto px-6 py-3 text-base text-center text-black bg-white border border-gray-200 rounded-full font-dmSans hover:bg-primary hover:text-white focus:ring-0"
              onClick={closeModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProp;
